type LogoSize = 'sm' | 'md' | 'lg' | 'xl';

type Variant = 'icon' | 'icon-circle' | 'full';
type Tier = 'Base' | 'Plus' | 'Peak';

export type TierLogoContentProps = {
  variant: Variant;
  size: LogoSize;
  className?: string;
};

type TierLogoProps = {
  tier: Tier;
  fullLogoRatio: number;
  children: JSX.Element;
  className?: string;
} & TierLogoContentProps;

export const logoSizeToPx: Record<LogoSize, number> = {
  sm: 12,
  md: 16,
  lg: 20,
  xl: 24
};

// https://www.figma.com/design/9Y1n3VoMJAcTGPe82bvySj/Denali-Components?node-id=17861-90210&m=dev

const getWidth = (height: number, ratio: number): number => Math.round(height * ratio * 10) / 10;

export const getSvgProperties = (
  fullLogoRatio: number,
  variant: TierLogoProps['variant'],
  size: LogoSize
): { width: number; height: number; viewBox: string } => {
  // "full" is the only variant that doesn't have equal width & height
  const ratio = variant === 'full' ? fullLogoRatio : 1;
  const height = logoSizeToPx[size];
  const width = getWidth(height, ratio);
  const viewBox = `0, 0, ${getWidth(logoSizeToPx.xl, ratio)} ${logoSizeToPx.xl}`;

  return { width, height, viewBox };
};

const TierLogo = ({ fullLogoRatio, variant, size, className, tier, children }: TierLogoProps): JSX.Element => {
  const { width, height, viewBox } = getSvgProperties(fullLogoRatio, variant, size);

  return (
    <svg className={className} width={width} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" version="1.1">
      <title>{tier}</title>
      {children}
    </svg>
  );
};

export default TierLogo;
