/* eslint-disable react/style-prop-object */
import classNames from 'classnames';
import { FormattedMessage, FormattedNumber } from '@alltrails/shared/react-intl';
import Coupon from 'types/Coupon';
import Typography from '@alltrails/shared/denali/components/Typography';
import Link from '@alltrails/shared/denali/components/Link';
import useUser from 'hooks/useUser';
import PurchaseTier from 'types/PurchaseTier';
import * as styles from './PlanSelection.module.scss';
import PeakLogo from '../TierLogo/Peak';
import PlusLogo from '../TierLogo/Plus';

// Figma file: https://www.figma.com/design/PtYSSpohQ9rL7ZWCcdsYJK/2025-Upsell-System?node-id=5541-138083&t=JJQ3VDYh6Mc0P7Ol-0

type Props = {
  tierSelected: PurchaseTier;
  setTierSelected: (tier: PurchaseTier) => void;
  hideCompareLink?: boolean;
  className?: string;
  plusCoupon?: Coupon;
};

const PlanSelection = ({ tierSelected, setTierSelected, hideCompareLink = false, className, plusCoupon }: Props) => {
  const { currencyCode, plans } = __AT_DATA__;
  const user = useUser();

  const scrollToFeatureList = () => {
    const featureListElement = document.getElementById('feature-list');
    if (featureListElement) {
      featureListElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isPromoEligible = user?.isPromoEligible;

  return (
    <div className={classNames(styles.planSelectionWrapper, className)}>
      {!hideCompareLink && (
        <div className={styles.topRow}>
          <Typography variant="text100bold">
            <FormattedMessage defaultMessage="Choose your plan" />
          </Typography>
          <Link
            variant="primary"
            onClick={() => {
              scrollToFeatureList();
            }}
            className={styles.compareLink}
          >
            <FormattedMessage defaultMessage="Compare" />
          </Link>
        </div>
      )}
      <button
        type="button"
        className={classNames(styles.planWrapper, { [styles.peakSelected]: tierSelected === 'peak' })}
        onClick={() => {
          setTierSelected('peak');
        }}
      >
        <div className={styles.peakRow}>
          <PeakLogo size="md" variant="full" />
          <Typography variant="text100bold" className={styles.badge}>
            <FormattedMessage defaultMessage="Includes Plus benefits" />
          </Typography>
        </div>
        <Typography variant="text200" className={styles.description}>
          <FormattedMessage defaultMessage="Trail conditions, custom routes, and more" />
        </Typography>
        <Typography variant="text100">
          {isPromoEligible ? (
            <FormattedMessage
              defaultMessage="7 days free, then {annualCost}/year (that’s {monthlyCost}/month)"
              values={{
                annualCost: (
                  <b>
                    <FormattedNumber currency={currencyCode} style="currency" value={plans.peak.priceTotal} />
                  </b>
                ),
                monthlyCost: <FormattedNumber currency={currencyCode} style="currency" value={plans.peak.priceMonth} />
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{annualCost}/year (that’s {monthlyCost}/month)"
              values={{
                annualCost: (
                  <b>
                    <FormattedNumber currency={currencyCode} style="currency" value={plans.peak.priceTotal} />
                  </b>
                ),
                monthlyCost: <FormattedNumber currency={currencyCode} style="currency" value={plans.peak.priceMonth} />
              }}
            />
          )}
        </Typography>
      </button>
      <button
        type="button"
        className={classNames(styles.planWrapper, { [styles.plusSelected]: tierSelected === 'plus' })}
        onClick={() => {
          setTierSelected('plus');
        }}
      >
        <PlusLogo size="md" variant="full" />
        <Typography variant="text200" className={styles.description}>
          <FormattedMessage defaultMessage="Offline maps, trail previews, and more" />
        </Typography>
        <Typography variant="text100">
          {isPromoEligible ? (
            <FormattedMessage
              defaultMessage="7 days free, then {annualCost}/year (that’s {monthlyCost}/month)"
              values={{
                annualCost: (
                  <b>
                    <FormattedNumber currency={currencyCode} style="currency" value={plans.plus.priceTotal} />
                  </b>
                ),
                monthlyCost: <FormattedNumber currency={currencyCode} style="currency" value={plans.plus.priceMonth} />
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{annualCost}/year (that’s {monthlyCost}/month)"
              values={{
                annualCost: (
                  <b>
                    <FormattedNumber currency={currencyCode} style="currency" value={plans.plus.priceTotal} />
                  </b>
                ),
                monthlyCost: <FormattedNumber currency={currencyCode} style="currency" value={plans.plus.priceMonth} />
              }}
            />
          )}
        </Typography>
      </button>
    </div>
  );
};
export default PlanSelection;
