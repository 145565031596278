import TierLogo, { TierLogoContentProps } from '../TierLogo';

const SvgContent = ({ variant }: { variant: TierLogoContentProps['variant'] }): JSX.Element => {
  switch (variant) {
    case 'icon':
      return (
        <path
          d="M16.1614 7.83857C13.8214 5.49429 13.9843 0 12 0C10.0157 0 10.1786 5.49429 7.83857 7.83857C5.49429 10.1829 0 10.0157 0 12C0 13.9843 5.49429 13.8214 7.83857 16.1614C10.1829 18.5057 10.02 24 12 24C13.98 24 13.8214 18.5057 16.1614 16.1614C18.5057 13.8171 24 13.98 24 12C24 10.02 18.5057 10.1829 16.1614 7.83857Z"
          fill="#4B7ECF"
        />
      );
    case 'icon-circle':
      return (
        <>
          <rect width="24" height="24" rx="12" fill="#4B7ECF" />
          <path
            d="M13.9073 10.4499C12.8348 9.37539 12.9095 6.85718 12 6.85718C11.0905 6.85718 11.1652 9.37539 10.0927 10.4499C9.01821 11.5243 6.5 11.4477 6.5 12.3572C6.5 13.2666 9.01821 13.192 10.0927 14.2645C11.1671 15.339 11.0925 17.8572 12 17.8572C12.9075 17.8572 12.8348 15.339 13.9073 14.2645C14.9818 13.19 17.5 13.2647 17.5 12.3572C17.5 11.4497 14.9818 11.5243 13.9073 10.4499Z"
            fill="white"
          />
        </>
      );
    case 'full':
      return (
        <>
          <g clipPath="url(#clip0_17861_90505)">
            <path
              d="M30.8232 1.85889H38.5491C39.9115 1.85889 41.1009 2.11653 42.1174 2.6283C43.1338 3.14006 43.9244 3.86006 44.4821 4.78477C45.0397 5.70948 45.3221 6.81418 45.3221 8.10242C45.3221 9.39065 45.0432 10.4495 44.4821 11.3918C43.9209 12.3342 43.1338 13.0612 42.1174 13.5765C41.1009 14.0883 39.9115 14.3459 38.5491 14.3459H34.0421V21.4577H30.8232V1.85889ZM34.0421 11.5436H38.2703C39.0362 11.5436 39.7032 11.4024 40.2715 11.1236C40.8397 10.8448 41.2809 10.4424 41.5879 9.92006C41.895 9.39771 42.0503 8.79065 42.0503 8.09889C42.0503 7.40712 41.895 6.77889 41.5879 6.26359C41.2809 5.75183 40.8468 5.353 40.2856 5.07418C39.7244 4.79536 39.0644 4.65418 38.2985 4.65418H34.0421V11.5436Z"
              fill="#161F13"
            />
            <path d="M47.6895 1.85889H50.7142V21.4542H47.6895V1.85889Z" fill="#161F13" />
            <path
              d="M53.7964 7.12132H56.8211V14.7625C56.8211 16.1637 57.0999 17.2154 57.6611 17.9248C58.2223 18.6343 59.0517 18.9872 60.1529 18.9872C60.9187 18.9872 61.5752 18.8001 62.1258 18.426C62.6764 18.0519 63.0999 17.5154 63.3999 16.8166C63.6964 16.1178 63.8481 15.2743 63.8481 14.2825V7.11426H66.8729V21.4472H64.2152L63.8811 19.6226C63.5145 20.0494 63.0795 20.4428 62.574 20.8048C61.8187 21.3448 60.7776 21.6166 59.454 21.6166C58.6882 21.6166 57.9646 21.5037 57.2834 21.2813C56.6023 21.059 55.9987 20.6919 55.4764 20.1907C54.954 19.686 54.5446 19.0154 54.2446 18.1754C53.9446 17.3354 53.7964 16.3084 53.7964 15.0943V7.12132Z"
              fill="#161F13"
            />
            <path
              d="M75.9578 21.6236C74.0343 21.6236 72.4778 21.193 71.2813 20.3353C70.0884 19.4777 69.4602 18.2741 69.4072 16.7247H72.3755C72.4319 17.473 72.7708 18.0836 73.399 18.56C74.0237 19.0365 74.8778 19.273 75.9613 19.273C76.5966 19.273 77.1402 19.1989 77.599 19.0471C78.0578 18.8989 78.4143 18.68 78.6755 18.3906C78.9366 18.1012 79.0672 17.7694 79.0672 17.3953C79.0672 16.873 78.9261 16.4953 78.6472 16.2624C78.3684 16.0294 77.9661 15.86 77.4437 15.7577C76.9213 15.6553 76.2861 15.5565 75.5413 15.4647C74.719 15.373 73.9602 15.2459 73.2613 15.0871C72.5625 14.9283 71.9449 14.7059 71.4119 14.4165C70.879 14.1271 70.459 13.7389 70.1519 13.2553C69.8449 12.7718 69.6896 12.1542 69.6896 11.4059C69.6896 10.4918 69.9402 9.69768 70.4449 9.02709C70.9496 8.3565 71.6449 7.83768 72.5308 7.47415C73.4166 7.11062 74.4296 6.93768 75.5696 6.95533C77.3237 6.95533 78.7743 7.34709 79.9213 8.13062C81.0684 8.91415 81.6896 10.0436 81.7813 11.5189H78.8131C78.739 10.8659 78.4178 10.3294 77.8461 9.90944C77.2778 9.48944 76.5331 9.28121 75.619 9.28121C74.7049 9.28121 74.0061 9.45062 73.4625 9.78591C72.9225 10.1212 72.6508 10.58 72.6508 11.1589C72.6508 11.6071 72.7919 11.9424 73.0708 12.1683C73.3496 12.3942 73.7519 12.5494 74.2743 12.6447C74.7966 12.74 75.4037 12.8212 76.0955 12.8953C77.1966 13.0259 78.1955 13.2165 79.0919 13.4706C79.9884 13.7212 80.7013 14.1342 81.2343 14.7024C81.7672 15.2706 82.0319 16.0894 82.0319 17.1518C82.0319 18.0659 81.7672 18.86 81.2343 19.5306C80.7013 20.2012 79.9778 20.72 79.0637 21.0836C78.1496 21.4471 77.1119 21.6306 75.9578 21.6306V21.6236Z"
              fill="#161F13"
            />
            <path
              d="M15.9741 7.74353C13.6553 5.42824 13.8176 0 11.8588 0C9.9 0 10.0624 5.42824 7.74353 7.74353C5.42824 10.0624 0 9.9 0 11.8588C0 13.8176 5.42824 13.6553 7.74353 15.9741C10.0588 18.2894 9.89647 23.7176 11.8588 23.7176C13.8212 23.7176 13.6553 18.2894 15.9741 15.9741C18.2894 13.6588 23.7176 13.8212 23.7176 11.8588C23.7176 9.89647 18.2894 10.0624 15.9741 7.74353Z"
              fill="#4B7ECF"
            />
          </g>
          <defs>
            <clipPath id="clip0_17861_90505">
              <rect width="82.5" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      );
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};

const PlusLogo = ({ size, variant, className }: TierLogoContentProps) => (
  <TierLogo
    fullLogoRatio={3.4375} // 82.5 / 24
    tier="Plus"
    size={size}
    variant={variant}
    className={className}
  >
    <SvgContent variant={variant} />
  </TierLogo>
);

export default PlusLogo;
