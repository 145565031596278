import { FormattedMessage } from '@alltrails/shared/react-intl';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import UpsellTrigger from '@alltrails/analytics/enums/UpsellTrigger';
import useUser from 'hooks/useUser';
import logUpsellMidpointShown from '@alltrails/analytics/events/logUpsellMidpointShown';
import logProUpsellFunFactShown from '@alltrails/analytics/events/logProUpsellFunFactShown';
import Typography from '@alltrails/shared/denali/components/Typography';
import Check from '@alltrails/shared/icons/Check';
import PurchaseScreenTypePlan from '@alltrails/analytics/enums/PurchaseScreenTypePlan';
import * as styles from './PeakFeatureList.module.scss';

const features = [
  { base: false, plus: false, peak: true, text: <FormattedMessage defaultMessage="Plan for on-trail conditions" /> },
  { base: false, plus: false, peak: true, text: <FormattedMessage defaultMessage="Build your own routes" /> },
  { base: false, plus: false, peak: true, text: <FormattedMessage defaultMessage="Customize AllTrails routes" /> },
  { base: false, plus: false, peak: true, text: <FormattedMessage defaultMessage="Explore community heatmaps" /> },
  { base: false, plus: true, peak: true, text: <FormattedMessage defaultMessage="Download offline maps" /> },
  { base: false, plus: true, peak: true, text: <FormattedMessage defaultMessage="Tour photos along the trail" /> },
  { base: false, plus: true, peak: true, text: <FormattedMessage defaultMessage="Get alerts for wrong turns" /> },
  { base: false, plus: true, peak: true, text: <FormattedMessage defaultMessage="Preview trails and terrain in 3D" /> },
  { base: false, plus: true, peak: true, text: <FormattedMessage defaultMessage="Share live activities with friends" /> },
  { base: false, plus: true, peak: true, text: <FormattedMessage defaultMessage="Find trails by distance from you" /> },
  { base: false, plus: true, peak: true, text: <FormattedMessage defaultMessage="Print maps for backup" /> },
  { base: false, plus: true, peak: true, text: <FormattedMessage defaultMessage="Go ad-free" /> },
  { base: true, plus: true, peak: true, text: <FormattedMessage defaultMessage="Save trails and create lists " /> },
  { base: true, plus: true, peak: true, text: <FormattedMessage defaultMessage="Navigate to stay on route" /> }
];

type Props = {
  tierSelected: 'base' | 'plus' | 'peak';
  disableMidpointTracking?: boolean;
  upsellTrigger?: UpsellTrigger;
  className?: string;
  hasHeaderScrollMargin?: boolean;
};

// Design File https://www.figma.com/design/PtYSSpohQ9rL7ZWCcdsYJK/2025-Upsell-System?node-id=5541-181584&m=dev
export default function PeakFeatureListTable({
  upsellTrigger,
  tierSelected = 'peak',
  disableMidpointTracking = false,
  className,
  hasHeaderScrollMargin
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const midpointRef = useRef<HTMLTableRowElement>(null);
  const user = useUser();
  const isPromoEligible = !user || user.isPromoEligible;

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          logProUpsellFunFactShown({ trigger: upsellTrigger });
        }
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [ref, upsellTrigger, user]);

  useEffect(() => {
    if (disableMidpointTracking) return;

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          logUpsellMidpointShown({
            purchase_screen_type_plan: isPromoEligible ? PurchaseScreenTypePlan.FreeTrial : PurchaseScreenTypePlan.NonTrial,
            trigger: upsellTrigger
          });
        }
      });
    });

    if (midpointRef.current) {
      observer.observe(midpointRef.current);
    }

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [disableMidpointTracking, isPromoEligible, midpointRef, upsellTrigger, user]);

  return (
    <table
      id="feature-list"
      className={classNames(styles.table, className, {
        [styles.peakSelected]: tierSelected === 'peak',
        [styles.plusSelected]: tierSelected === 'plus',
        [styles.baseSelected]: tierSelected === 'base',
        [styles.headerScrollMargin]: hasHeaderScrollMargin
      })}
    >
      <thead>
        <tr>
          <th>
            <FormattedMessage defaultMessage="What you get" />
          </th>
          <th>Base</th>
          <th>Plus</th>
          <th>Peak</th>
        </tr>
      </thead>
      <tbody>
        {features.map((feature, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={index} {...(index === 7 ? { ref: midpointRef } : {})}>
            <td>
              <Typography variant="text200">{feature.text}</Typography>
            </td>
            <td>{feature.base && <Check color={tierSelected === 'base' ? '--color-text-primary' : '--color-text-tertiary'} size="sm" />}</td>
            <td>{feature.plus && <Check color={tierSelected === 'plus' ? '--color-text-primary' : '--color-text-tertiary'} size="sm" />}</td>
            <td>
              <Check color={tierSelected === 'peak' ? '--color-text-primary' : '--color-text-tertiary'} size="sm" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
