import { FormattedMessage } from '@alltrails/shared/react-intl';
import { useEffect, useRef } from 'react';
import UpsellTrigger from '@alltrails/analytics/enums/UpsellTrigger';
import logProUpsellFunFactShown from '@alltrails/analytics/events/logProUpsellFunFactShown';
import useUser from 'hooks/useUser';
import Typography from '@alltrails/shared/denali/components/Typography';
import * as styles from './FunFact.module.scss';

type Props = {
  upsellTrigger: UpsellTrigger;
};

// https://www.figma.com/design/PtYSSpohQ9rL7ZWCcdsYJK/2025-Upsell-System?node-id=5545-315282&m=dev
export default function FunFact({ upsellTrigger }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const user = useUser();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          logProUpsellFunFactShown({ trigger: upsellTrigger });
        }
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [ref, upsellTrigger, user]);

  return (
    <div ref={ref}>
      <Typography className={styles.did} variant="text100bold">
        <FormattedMessage defaultMessage="Did you know?" />
      </Typography>
      <div className={styles.fact}>
        <div className={styles.threex}>
          <FormattedMessage defaultMessage="3x" />
        </div>
        <div className={styles.line} />
        <Typography variant="text200">
          <FormattedMessage defaultMessage="Subscribers are 3 times more likely to hit the trail" />
        </Typography>
      </div>
    </div>
  );
}
