import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { ReactNode, useState } from 'react';
import UpsellTrigger from '@alltrails/analytics/enums/UpsellTrigger';
import Typography from '@alltrails/shared/denali/components/Typography';
import useUser from 'hooks/useUser';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import PurchaseTier from 'types/PurchaseTier';
import useExperiments from 'hooks/useExperiments';
import BaseUpsellModal from '../UpsellSystem/BaseUpsell/BaseUpsellModal';
import PlanSelection from '../PlanSelection/PlanSelection';
import PeakFeatureList from '../UpsellSystem/PeakFeatureList/PeakFeatureList';

type Props = {
  heading: ReactNode;
  isOpen: boolean;
  onCloseRequest: () => void;
  promoEligibleHeading: ReactNode;
  returnTo?: string;
  trigger: CarouselDisplayTrigger;
  upsellTrigger: UpsellTrigger;
};

// https://www.figma.com/design/PtYSSpohQ9rL7ZWCcdsYJK/2025-Upsell-System?node-id=5543-297520&t=9h0uykTwgb7vGafI-0
export default function PlusPreviewUpsell({ trigger, heading, promoEligibleHeading, onCloseRequest, isOpen, upsellTrigger, returnTo }: Props) {
  const user = useUser();
  const isPromoEligible = user?.isPromoEligible;
  const defaultTier = useExperiments()['rm-peak-soft-launch']?.value === 'peak_default' ? 'peak' : 'plus';
  const [tierSelected, setTierSelected] = useState<PurchaseTier>(defaultTier);

  return (
    <BaseUpsellModal
      primaryButtonText={
        tierSelected === 'peak' ? <FormattedMessage defaultMessage="Continue with Peak" /> : <FormattedMessage defaultMessage="Continue with Plus" />
      }
      isOpen={isOpen}
      onCloseRequest={onCloseRequest}
      returnTo={returnTo}
      trigger={trigger}
      upsellTrigger={upsellTrigger}
      tierSelected={tierSelected}
    >
      <>
        <Typography variant="heading400">{isPromoEligible ? promoEligibleHeading : heading}</Typography>
        <PlanSelection tierSelected={tierSelected} setTierSelected={setTierSelected} />
        <PeakFeatureList tierSelected={tierSelected} upsellTrigger={upsellTrigger} />
      </>
    </BaseUpsellModal>
  );
}
