import { Suspense, useCallback, useEffect, useState, lazy, useRef } from 'react';
import { NoSsr } from '@material-ui/core';
import { ErrorBoundary } from 'react-error-boundary';
import TabBar from '@alltrails/shared/denali/components/TabBar';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import logTrailDetailsConditionsViewed from '@alltrails/analytics/events/logTrailDetailsConditionsViewed';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import useIsVisible from '@alltrails/shared/hooks/useIsVisible';
import type { User } from 'types/User';
import { wrapUrlSafe } from 'utils/language_support_util';
import UpsellTrigger from '@alltrails/analytics/enums/UpsellTrigger';
import { useSelector } from '../redux';
import { useTrailWeatherContext, useTrailWeather } from '../trailWeatherContext';
import * as styles from './styles/styles.module.scss';
import TrailWeatherError from './TrailWeatherError';
import TrailWeatherOverviewShimmer from '../TrailWeatherOverviewShimmer';
import TrailWeatherBaseShimmer from './TrailWeatherBaseShimmer';
import retry from '../utils/lazyWithRetry';

const RTTCUpsellModal = lazy(() => retry(() => import('components/RTTCUpsellModal')));
const SignupModal = lazy(() => retry(() => import('components/SignupModal')));

const TrailSevenDayConditionsPicker = lazy(() => retry(() => import('../TrailSevenDayConditionsPicker')));
const TrailRealTimeWidgets = lazy(() => retry(() => import('../TrailRealTimeWidgets')));
const TrailWeatherOverview = lazy(() => retry(() => import('../TrailWeatherOverview')));
const TrailUpsellRiver = lazy(() => retry(() => import('../TrailRealTimeWidgets/TrailUpsellRiver')));

const messages = defineMessages({
  conditions: {
    defaultMessage: 'Conditions'
  }
});

function TrailWeatherBase(): JSX.Element {
  const ref = useRef<HTMLDivElement>();
  const intl = useIntl();
  const isVisible = useIsVisible(ref, { threshold: 1 });
  const weatherInfo = useTrailWeather();
  const { getMapWeather: loadMapWeather, getOverview, getConditions, trail } = useTrailWeatherContext();
  const [offline, setOffline] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isRTTCUpsellModalOpen, setIsRTTCUpsellModalOpen] = useState(false);
  const [signupModalTrigger, setSignupModalTrigger] = useState<CarouselDisplayTrigger>(
    CarouselDisplayTrigger.TrailConditionsWeatherAlongTheTrailUpsellCardTapped
  );
  const [isInitializing, setIsInitializing] = useState(
    !weatherInfo ||
      (weatherInfo?.overviewLoadState === 'Uninitialized' && weatherInfo?.lifestyleLoadState === 'Uninitialized') ||
      (weatherInfo?.overviewLoadState === 'Loading' && weatherInfo?.lifestyleLoadState === 'Loading')
  );
  const { languageRegionCode, user } = useSelector(state => ({
    languageRegionCode: state.context.languageRegionCode,
    user: state.context.currentUser
  }));

  const onUpsellClick = useCallback(
    (trigger: CarouselDisplayTrigger) => {
      setSignupModalTrigger(trigger);
      if (!user) {
        setIsSignupModalOpen(true);
      } else if (!user.pro) {
        setIsRTTCUpsellModalOpen(true);
      }
    },
    [user]
  );

  const retryAll = () => {
    setIsInitializing(true);
    setOffline(false);
  };

  useEffect(() => {
    if (isVisible) {
      logTrailDetailsConditionsViewed({ trail_id: trail.trail_id });
    }
  }, [isVisible, trail.trail_id]);

  useEffect(() => {
    if (isInitializing) {
      loadMapWeather().then(() => {
        setOffline(!window.navigator.onLine);
        setIsInitializing(false);
      });
    }
  }, [isInitializing, loadMapWeather]);

  useEffect(() => {
    if (
      !isInitializing &&
      !!user &&
      (!weatherInfo || (user.pro && (weatherInfo?.lifestyleLoadState === 'Uninitialized' || weatherInfo?.overviewLoadState === 'Uninitialized')))
    ) {
      // user has logged in after initialization
      loadMapWeather();
    }
  }, [getConditions, isInitializing, loadMapWeather, user, user?.pro, weatherInfo, weatherInfo?.lifestyleLoadState]);

  const displayWeather = !isInitializing && !offline && !!weatherInfo;

  return (
    <div ref={ref}>
      <TabBar
        activeTab="Conditions"
        className={styles.tabBar}
        onChange={() => {
          // One tab just for display, no onChange needed
        }}
        tabs={[{ id: 'Conditions', label: intl.formatMessage(messages.conditions), testId: 'trail-conditions-tab-conditions' }]}
        testId="trail-conditions-tab-bar"
      />
      <NoSsr fallback={<TrailWeatherBaseShimmer hasPlus={user?.pro} />}>
        <ErrorBoundary fallback={<TrailWeatherError onRetry={retryAll /* TODO: PLAN-2183 re-fetch JS bundles, or reload the window */} />}>
          <Suspense fallback={<TrailWeatherBaseShimmer hasPlus={user?.pro} />}>
            {isInitializing && <TrailWeatherBaseShimmer hasPlus={user?.pro} />}
            {offline && <TrailWeatherError offline={offline} onRetry={retryAll} />}
            {displayWeather &&
              (weatherInfo.overviewLoadState === 'Error' && weatherInfo.lifestyleLoadState === 'Error' ? (
                <TrailWeatherError onRetry={retryAll} />
              ) : (
                <>
                  {weatherInfo.overviewLoadState === 'Success' && <TrailSevenDayConditionsPicker />}
                  {weatherInfo.overviewLoadState === 'Loading' && <TrailWeatherOverviewShimmer />}
                  {weatherInfo.overviewLoadState === 'Error' && <TrailWeatherError onRetry={getOverview} />}
                  {weatherInfo.overviewLoadState === 'Success' && <TrailWeatherOverview />}
                  {user?.pro ? <TrailRealTimeWidgets /> : <TrailUpsellRiver onUpsellClick={onUpsellClick} />}
                </>
              ))}
          </Suspense>
        </ErrorBoundary>
        <Suspense fallback={null}>
          <SignupModal
            trigger={signupModalTrigger}
            isOpen={isSignupModalOpen}
            onRequestClose={() => {
              setIsSignupModalOpen(false);
            }}
            onSuccess={(loggedInUser: User) => {
              setIsSignupModalOpen(false);

              if (!loggedInUser.pro) {
                setIsRTTCUpsellModalOpen(true);
              }
            }}
          />
        </Suspense>
        <Suspense fallback={null}>
          <RTTCUpsellModal
            trigger={UpsellTrigger.RTTC}
            isOpen={isRTTCUpsellModalOpen}
            onRequestClose={() => {
              setIsRTTCUpsellModalOpen(false);
            }}
            returnTo={wrapUrlSafe(`/${trail.slug}`, languageRegionCode)}
            upsellTrigger={signupModalTrigger}
          />
        </Suspense>
      </NoSsr>
    </div>
  );
}

export default TrailWeatherBase;
