import TierLogo, { TierLogoContentProps } from '../TierLogo';

const SvgContent = ({ variant }: { variant: TierLogoContentProps['variant'] }): JSX.Element => {
  switch (variant) {
    case 'icon':
      return (
        <path
          d="M12.3212 2.46436C9.16531 2.46436 -0.77106 19.8519 1.08637 21.7094C2.94379 23.5668 8.52467 16.1586 12.3212 16.1586C16.1178 16.1586 21.6986 23.5668 23.5561 21.7094C25.4135 19.8519 15.4771 2.46436 12.3212 2.46436Z"
          fill="#AC76F6"
        />
      );
    case 'icon-circle':
      return (
        <>
          <rect width="24" height="24" rx="12" fill="#AC76F6" />
          <path
            d="M12 7C10.5376 7 5.93309 15.0573 6.79382 15.918C7.65454 16.7788 10.2407 13.3458 12 13.3458C13.7593 13.3458 16.3455 16.7788 17.2062 15.918C18.0669 15.0573 13.4624 7 12 7Z"
            fill="white"
          />
        </>
      );
    case 'full':
      return (
        <>
          <g clipPath="url(#clip0_17861_90578)">
            <path
              d="M66.8186 20.6175C65.2198 20.6175 63.928 20.2399 62.9469 19.4881C61.9657 18.7364 61.4751 17.6846 61.4751 16.3328C61.4751 14.8293 62.0116 13.6717 63.088 12.8634C64.1645 12.0552 65.721 11.6493 67.7575 11.6493H71.4139V10.7634C71.4139 9.9446 71.1316 9.30225 70.5704 8.83636C70.0092 8.37048 69.2222 8.13754 68.2127 8.13754C67.2986 8.13754 66.5504 8.33165 65.9716 8.72342C65.3892 9.11518 65.0433 9.63401 64.9304 10.2799H61.9022C61.9975 9.32695 62.3257 8.50812 62.8869 7.82342C63.448 7.13871 64.1927 6.60577 65.1139 6.2246C66.0386 5.84342 67.1186 5.65283 68.3575 5.65283C70.2986 5.65283 71.8022 6.1046 72.8716 7.00812C73.9375 7.91165 74.4704 9.23166 74.4704 10.9646V20.4481H71.7845L71.514 18.3588C71.1095 18.8742 70.6322 19.3314 70.0833 19.7317C69.2751 20.3211 68.1845 20.6175 66.8116 20.6175H66.8186ZM67.3304 18.2175C68.6045 18.2175 69.5998 17.8717 70.3163 17.1764C71.0292 16.4811 71.3963 15.574 71.4174 14.4481V13.9328H67.588C66.6351 13.9328 65.901 14.1128 65.3892 14.4764C64.8739 14.8399 64.6198 15.3905 64.6198 16.1317C64.6198 16.7422 64.8633 17.2399 65.3469 17.6317C65.8339 18.0234 66.4939 18.2175 67.3339 18.2175H67.3304Z"
              fill="#161F13"
            />
            <path
              d="M30 0.5H37.8847C39.2753 0.5 40.4894 0.76118 41.5271 1.28706C42.5647 1.80942 43.3694 2.54353 43.9412 3.48588C44.5129 4.42824 44.7988 5.55765 44.7988 6.87059C44.7988 8.18353 44.5129 9.26706 43.9412 10.2271C43.3694 11.1906 42.5647 11.9318 41.5271 12.4541C40.4894 12.9765 39.2753 13.2412 37.8847 13.2412H33.2859V20.4976H30V0.5ZM33.2824 3.3553V10.3824H37.5953C38.3753 10.3824 39.0565 10.2376 39.6388 9.9553C40.2212 9.66942 40.6659 9.26 40.98 8.72706C41.2941 8.19412 41.4529 7.57647 41.4529 6.87059C41.4529 6.16471 41.2941 5.52235 40.98 5C40.6659 4.47765 40.2247 4.07177 39.6529 3.78589C39.0812 3.5 38.4071 3.35883 37.6235 3.35883L33.2824 3.3553Z"
              fill="#161F13"
            />
            <path
              d="M52.9057 20.6175C51.381 20.6175 50.0575 20.3069 48.9351 19.6893C47.8128 19.0716 46.9445 18.1999 46.3375 17.074C45.7269 15.9516 45.4233 14.6458 45.4233 13.1599C45.4233 11.674 45.7233 10.3434 46.3233 9.21752C46.9233 8.09517 47.7669 7.21634 48.8504 6.5881C49.9375 5.95987 51.1939 5.64575 52.6198 5.64575C54.0457 5.64575 55.2633 5.9281 56.3186 6.48928C57.3739 7.05046 58.2033 7.85517 58.8033 8.9034C59.4033 9.95164 59.721 11.1905 59.7598 12.6163C59.7598 12.8069 59.7492 13.001 59.7316 13.2022C59.7139 13.4034 59.6928 13.5975 59.6751 13.7881H48.6492V13.9893C48.688 14.8081 48.8716 15.5281 49.2069 16.1458C49.5386 16.7634 50.0116 17.2469 50.6222 17.5893C51.2328 17.9316 51.9351 18.1046 52.7363 18.1046C53.668 18.1046 54.4657 17.8752 55.1222 17.4199C55.7786 16.961 56.2128 16.3328 56.421 15.5352H59.5057C59.3327 16.4881 58.9586 17.3493 58.3763 18.1187C57.7939 18.8916 57.0422 19.4987 56.121 19.9469C55.1963 20.3952 54.1269 20.6175 52.9092 20.6175H52.9057ZM56.5622 11.5328C56.428 10.4493 56.0151 9.60928 55.3198 9.01987C54.6245 8.43046 53.7351 8.13399 52.648 8.13399C52.0022 8.13399 51.3951 8.27163 50.8339 8.54693C50.2728 8.82222 49.8069 9.21399 49.4328 9.7187C49.0622 10.2234 48.8363 10.8269 48.7622 11.5328H56.5622Z"
              fill="#161F13"
            />
            <path
              d="M76.938 0.5H80.0227V12.9553L86.6509 5.87177H90.2792L84.7662 11.7835L90.6215 20.4941H86.965L82.6521 14.0388L80.0227 16.7812V20.4941H76.938V0.5Z"
              fill="#161F13"
            />
            <path
              d="M11.1391 1.42236C8.07555 1.42236 -1.58092 18.3177 0.222607 20.1212C2.02614 21.9247 7.45084 14.7282 11.1391 14.7282C14.8273 14.7282 20.2485 21.9282 22.0555 20.1212C23.8591 18.3177 14.2061 1.42236 11.1391 1.42236Z"
              fill="#AC76F6"
            />
          </g>
          <defs>
            <clipPath id="clip0_17861_90578">
              <rect width="90.6211" height="20.1176" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </>
      );
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};

const PeakLogo = ({ size, variant, className }: TierLogoContentProps) => (
  <TierLogo
    fullLogoRatio={4.5045} // 90.621w / 20.118h
    tier="Peak"
    size={size}
    variant={variant}
    className={className}
  >
    <SvgContent variant={variant} />
  </TierLogo>
);

export default PeakLogo;
