import { useEffect } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import UpsellTrigger from '@alltrails/analytics/enums/UpsellTrigger';
import useUser from 'hooks/useUser';
import ProductType from '@alltrails/analytics/enums/ProductType';
import logUpsellShown from '@alltrails/analytics/events/logUpsellShown';
import PurchaseScreenTypePlan from '@alltrails/analytics/enums/PurchaseScreenTypePlan';
import logProUpsellScreenDismissed from '@alltrails/analytics/events/logProUpsellScreenDismissed';
import logWebProModalPurchaseButtonTapped from '@alltrails/analytics/events/logWebProModalPurchaseButtonTapped';
import DismissAction from '@alltrails/analytics/enums/DismissAction';
import { Modal } from '@alltrails/modules/Modal';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import PurchaseTier from 'types/PurchaseTier';
import * as styles from './BaseUpsellModal.module.scss';

type Props = {
  children?: React.ReactNode;
  contentsClassName?: string;
  isOpen: boolean;
  onCloseRequest: () => void;
  primaryButtonText?: React.ReactNode;
  returnTo?: string;
  trigger: CarouselDisplayTrigger;
  upsellTrigger: UpsellTrigger;
  tierSelected: PurchaseTier;
};

export default function BaseUpsellStep({
  children,
  contentsClassName,
  onCloseRequest,
  primaryButtonText,
  trigger,
  returnTo,
  upsellTrigger,
  isOpen,
  tierSelected
}: Props) {
  const user = useUser();
  const isPromoEligible = user?.isPromoEligible;
  const languageRegionCode = useLanguageRegionCode();

  const ctaHref = `/plans/checkout?${new URLSearchParams({ returnTo, tier: tierSelected }).toString()}`;

  useEffect(() => {
    logUpsellShown({
      purchase_screen_type_plan: isPromoEligible ? PurchaseScreenTypePlan.FreeTrial : PurchaseScreenTypePlan.NonTrial,
      trigger: upsellTrigger
    });
  }, [isPromoEligible, trigger, upsellTrigger]);

  const handleCloseRequest = () => {
    logProUpsellScreenDismissed({
      dismiss_action: DismissAction.XOut,
      trigger,
      purchase_screen_type_plan: isPromoEligible ? PurchaseScreenTypePlan.FreeTrial : PurchaseScreenTypePlan.NonTrial
    });
    onCloseRequest();
  };

  const handleCtaClick = () => {
    logWebProModalPurchaseButtonTapped({
      product_type: isPromoEligible ? ProductType.Free : ProductType.Plus,
      purchase_screen_type_plan: isPromoEligible ? PurchaseScreenTypePlan.FreeTrial : PurchaseScreenTypePlan.NonTrial,
      trigger: upsellTrigger
    });

    window.location.assign(wrapUrlSafe(`/plans/checkout?tier=${tierSelected}`, languageRegionCode));
  };

  const primaryButton = {
    onClick: handleCtaClick,
    text:
      primaryButtonText ??
      (isPromoEligible ? <FormattedMessage defaultMessage="Start your free trial" /> : <FormattedMessage defaultMessage="Upgrade to AllTrails+" />),
    testId: 'checkout-cta',
    linkInfo: {
      href: ctaHref
    }
  };

  return (
    <Modal
      buttons={{
        fill: true,
        primaryButton
      }}
      isOpen={isOpen}
      caption={<FormattedMessage defaultMessage="Cancel anytime" />}
      onCloseRequest={handleCloseRequest}
      contentsClassName={contentsClassName}
    >
      <div className={styles.contents}>{children}</div>
    </Modal>
  );
}
