import Link from '@alltrails/shared/denali/components/Link';
import UpsellTrigger from '@alltrails/analytics/enums/UpsellTrigger';
import * as styles from './PeakFeatureList.module.scss';
import PeakFeatureListTable from './PeakFeatureListTable';
import FunFact from './FunFact/FunFact';

type Props = {
  tierSelected: 'base' | 'plus' | 'peak';
  upsellTrigger: UpsellTrigger;
  hasHeaderScrollMargin?: boolean;
};

// Design File https://www.figma.com/design/PtYSSpohQ9rL7ZWCcdsYJK/2025-Upsell-System?node-id=5541-181584&m=dev
export default function PeakFeatureList({ upsellTrigger, tierSelected = 'peak', hasHeaderScrollMargin }: Props) {
  return (
    <>
      <PeakFeatureListTable tierSelected={tierSelected} upsellTrigger={upsellTrigger} hasHeaderScrollMargin={hasHeaderScrollMargin} />
      <FunFact upsellTrigger={upsellTrigger} />
      <div className={styles.linksContainer}>
        <Link href="/privacy" className={styles.link} target="_blank" variant="secondary">
          Privacy Policy
        </Link>
        <div className={styles.divider} />
        <Link href="/terms" className={styles.link} target="_blank">
          Terms of Service
        </Link>
      </div>
    </>
  );
}
